import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Meta from 'vue-meta';
import VueProgressiveImage from 'vue-progressive-image'
import KsVueScrollmagic from 'ks-vue-scrollmagic'

Vue.use(KsVueScrollmagic)
Vue.use(VueProgressiveImage)
Vue.use(Meta)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
