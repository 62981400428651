<template>
    <footer class="footer">
        <div class="container is-fullhd">
            <div class="columns">
                <div class="column">
                    <div>
                        <nav class="c-nav c-nav--footer" :key="index" v-for="(page, index) in PageInFooter">
                            <div v-if="page.menu === 'footer'" class="c-nav__menu c-nav__menu--footer">
                                <router-link class="c-nav__item c-nav__item--footer" :key="index" :to="{ name:'Page', params: { pageSlug: menuItem.slug } }" v-for="(menuItem, index) in page.page">
                                    {{ menuItem.title }}
                                </router-link>
                            </div>
                        </nav>
                    </div>
                </div>
                <div class="column">
                  <div class="c-copyright" v-html="copyright"></div>
                </div>
            </div> <!-- columns -->
        </div>
    </footer>
</template>


<script>
  import axios from 'axios'
  import _ from 'lodash'

  export default {
    name: 'Footer',

    data() {
      return {
        pageData: null,
        menu: null,
        copyright: null
      }
    },

    mounted () {
        if (localStorage.pageData) {
          this.pageData = JSON.parse(localStorage.pageData)
          this.copyright = this.pageData.copyright
        }
    },

    created() {
      this.getMenu()
    },

    methods: {
      getMenu() {
        axios.get(process.env.VUE_APP_PAGES_URL)
            .then(res => {
              this.menu = res.data.entries
            })
            .catch(e => {
              /*eslint no-console: ["error", { allow: ["error"] }] */
              console.error(e)
            })
      }
    },

    computed: {
      PageInFooter () {
        return _.chain(this.menu)
            .groupBy(item => item.menu)
            .map((page, menu) => ({
              menu,
              page
            }))
            .value()
      }
    }
  }
</script>
