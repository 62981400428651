<template>
  <div id="app" class="o-app" :class="{ 'o-app--is-loaded' : loaded }">
      <div class="o-layout">
          <PageLoader />
          <PageHeader/>
          <router-view mode="out-in" :key="$route.path"></router-view>
          <PageFooter/>
      </div>
  </div>
</template>

<script>
import axios from 'axios'
import { EventBus } from '@/event-bus'
import PageLoader from '@/components/Loader'
import PageHeader from '@/components/Header'
import PageFooter from '@/components/Footer'

export default {
  name: 'app',

  data () {
    return {
      pageData: null,
      loaded: false
    }
  },

  mounted () {
    if (localStorage.pageData) {
      this.pageData = JSON.parse(localStorage.pageData)
      document.title = this.pageData.name + ' — ' + this.pageData.description
    } else {
      this.getSiteInfo()
    }
  },

  watch: {
    pageData(newPageData) {
      localStorage.pageData = JSON.stringify(newPageData)
    }
  },

  created () {
    EventBus.$on('pageLoaded', payload => {
      this.loaded = payload
    })
  },

  methods: {
    getSiteInfo () {
      axios.get(process.env.VUE_APP_SITEINFO_URL)
          .then(res => {
            this.pageData = res.data
            document.title = this.pageData.name + ' — ' + this.pageData.description
          })
          .catch(e => {
            /*eslint no-console: ["error", { allow: ["error"] }] */
            console.error(e)
          })
    }
  },

  components: {
    PageHeader,
    PageFooter,
    PageLoader
  }
}
</script>

<style lang="scss" src="./assets/styles/main.scss"></style>
