import Vue from 'vue'
import Router from 'vue-router'
import VueScrollTo from 'vue-scrollto'

Vue.use(Router)

function loadView(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`)
}

const router = new Router ({
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    //https://router.vuejs.org/guide/advanced/scroll-behavior.html
    if (to.hash && from.name !== 'Page') {
        VueScrollTo.scrollTo(to.hash, {easing: '[0.5, 1.0, 0.9, 1.0]', duration: 700});
        return { selector: to.hash }
        } else if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 }
        }
  },
  routes: [
    {
      path: '/',
      name: 'Home',
      component: loadView('Home')
    },
    {
      path: '/page/:pageSlug',
      name: 'Page',
      component: loadView('Page')
    },
    {
      path: '/anfrage',
      name: 'Anfrage',
      component: loadView('Request')
    }
  ]
})

export default router
